import React from "react";
import root from "window-or-global";
/* global google */
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hidden from "@material-ui/core/Hidden";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Autosuggest from "react-autosuggest";
import CustomHidden from "ps-components/CustomHidden/CustomHidden";
import Close from "@material-ui/icons/Close";
import LocationOn from "@material-ui/icons/LocationOn";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import Share from "@material-ui/icons/Share";
import List from "@material-ui/icons/List";
import {
  removeCommas,
  iconData,
  getType,
  SHOW_MAP,
  TRUE,
  elementAutoScroll
} from "ps-components/Maps/Util.jsx";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import ReactSVG from "react-svg";
import CustomSnackbar from "components/Snackbar/Snackbar";
import { withPrefix } from "gatsby";
import defaultMap from "assets/img/default-map.png";
import { browserDocument } from "util/localStorageHelper";

const theme = {
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: `none`,
    maxHeight: '300px',
    overflowY: 'auto',
  },
  suggestion: {
    cursor: `pointer`,
    padding: `10px 20px`
  },
  suggestionHighlighted: {
    backgroundColor: `#ddd`
  }
};

const empanelment_status = `empanelment_status`,
  office_type = `office_type`;

class CustomMap extends React.Component {
  state = {
    mapCard: null,
    suggestions: [],
    value: ``,
    detailPane: null,
    markers: null,
    mapLoaded: false,
    notFound: null
  };

  map = null;

  onSuggestionsFetchRequested = async ({ value }) => {
    var suggestions = await this.getSuggestions(value);
    this.setState({
      suggestions:
        suggestions && suggestions.responseList
          ? suggestions.responseList.slice(0, 10)
          : []
    });
    if (suggestions && suggestions.responseList)
      this.props.updateResponse(suggestions);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestions = async value => {
    var encodedValue = encodeURIComponent(value);
    if (encodedValue.length > 3) {
      var url =
        this.props.url +
        `?pageNo=1&totalRecord=30&search=` +
        encodedValue +
        `&category=` +
        this.props.category;
      var res = await fetch(url, {
        method: `GET`
      });
      var responseSuggestions = await res.json();
      return responseSuggestions;
    }
  };

  getSuggestionValue = suggestion => {
    return suggestion.location !== undefined ? suggestion.location : ``;
  };

  renderSuggestion = suggestion => {
    let { value } = this.state;
    value = value.replace(/\)/g,`\\)`).replace(/\(/g,`\\(`);
    suggestion.location = removeCommas(suggestion.location);
    var location = `${
      suggestion.hasOwnProperty(office_type)
        ? suggestion.office_type + ` - `
        : ``
    }${suggestion.location.replace(new RegExp(value, `ig`), function(matched) {
      return `<strong>` + matched + `</strong>`;
    })}`;

    return <Markdown source={location} escapeHtml={false} />;
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  handleClose = () => {
    const { response } = this.props;
    this.setState({ detailPane: null });
    this.createMarkers(response, null);
  };

  getTypeInDetailPane = detail => {
    const { classes } = this.props;
    let detail_type = ``;
    [empanelment_status, office_type].map(type => {
      if (detail.hasOwnProperty(type)) {
        detail_type = type;
      }
    });
    if (detail_type != ``) {
      if (detail[detail_type] != `` && detail[detail_type] != undefined) {
        return (
          <>
            {detail[detail_type].split(`/`).map((type, key) => (
              <ReactSVG
                key={key}
                src={withPrefix(iconData[getType(type).icon].icon)}
                style={{ width: `35px`, height: `35px`, float: `left` }}
              />
            ))}
            <h4
              className={classes.cardTitle}
              style={{ display: `inline-block`, paddingLeft: `10px` }}
            >
              Type
            </h4>
            <div className={classes.description}>
              {detail[detail_type]
                .split(`/`)
                .map(
                  (type, id) =>
                    (id == 0 ? `` : ` / `) + getType(type).description
                )}
            </div>
          </>
        );
      }
    }
    return ``;
  };

  getDetailPane = (classes, detail) => {
    return (
      <div>
        <Close
          onClick={this.handleClose}
          style={{ float: `right`, cursor: `pointer` }}
        />
        <br />
        <GridContainer>
          <GridItem xs={1} md={1} style={{ padding: `0` }}>
            <LocationOn color="primary" style={{ marginTop: `.625rem` }} />
          </GridItem>
          <GridItem xs={11} md={11} style={{ padding: `0` }}>
            <h4
              className={classes.cardTitle}
              style={{ display: `inline-block`, paddingLeft: `20px` }}
            >
              {` `}
              {detail.title}
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.description}>
          {removeCommas(
            [`title`, `address`, `area`, `city`, `district`, `state`]
              .map(field => {
                if (detail[field] && detail[field] != ``) return detail[field];
              })
              .join(`,`) +
              `${detail[`pin_code`] ? ` - ` + detail[`pin_code`] : ``}`
          )}
        </div>

        {detail.hasOwnProperty(`contact_number`) &&
          detail.contact_number != undefined &&
          detail.contact_number != `` && (
            <>
              <Phone color="primary" style={{ marginTop: `.625rem` }} />
              <h4
                className={classes.cardTitle}
                style={{ display: `inline-block`, paddingLeft: `20px` }}
              >
                {` `}
                Contact Number
              </h4>
              {this.props.id == "PHCWomen" ? (<div
                className={classes.description}
              >{`${detail.std_code}${detail.contact_number}`}</div>)
            : (<div
              className={classes.description}
            >{`${detail.std_code}-${detail.contact_number}`}</div>)}
            </>
        )}
        {detail.hasOwnProperty(`email_id`) &&
          (detail.email_id != `` && (
            <>
              <Email color="primary" style={{ marginTop: `.625rem` }} />
              <h4
                className={classes.cardTitle}
                style={{ display: `inline-block`, paddingLeft: `20px` }}
              >
                {` `}
                Email
              </h4>
              <div className={classes.description}>{detail.email_id}</div>
            </>
          ))}
        {this.getTypeInDetailPane(detail)}
        <div onClick={this.testWebShare}>
          <Share color="primary" style={{ marginTop: `.625rem` }} />
          <h4
            className={classes.cardTitle}
            style={{ display: `inline-block`, paddingLeft: `20px` }}
          >
            {` `}
            Share
          </h4>
        </div>
        <div style={{ fontSize: `20px` }}>
          <Button justIcon simple color="facebook">
            <FacebookShareButton
              title={detail.title}
              url={`https://www.google.com/maps/search/?api=1&query=${detail.latitude},${detail.longitude}&query_place_id=${detail.place_id}`}
            >
              <ReactSVG src={withPrefix(`/web_facebook.svg`)} />
            </FacebookShareButton>
          </Button>

          <Button justIcon simple color="twitter">
            <TwitterShareButton
              title={detail.title}
              url={`https://www.google.com/maps/search/?api=1&query=${detail.latitude},${detail.longitude}&query_place_id=${detail.place_id}`}
            >
              <ReactSVG src={withPrefix(`/web_twitter.svg`)} />
            </TwitterShareButton>
          </Button>
          <Button justIcon simple color="primary">
            <WhatsappShareButton
              title={detail.title}
              url={`https://www.google.com/maps/search/?api=1&query=${detail.latitude},${detail.longitude}&query_place_id=${detail.place_id}`}
            >
              <ReactSVG src={withPrefix(`/web_whatsapp.svg`)} />
            </WhatsappShareButton>
          </Button>
        </div>
      </div>
    );
  };

  handleItemClick = e => {
    const { classes, response } = this.props;
    // let title=(typeof e === `string`) ? e : e.target.id
    if (response && response.responseList.length >= 1) {
      var detail = response.responseList.filter(item => {
        return item.title === e.target.id;
      })[0];
      if (detail) {
        let detailPane = this.getDetailPane(classes, detail);
        this.setState({
          detailPane: detailPane
        });
      }
    }
  };

  handleItemClickOnMapCard = e => {
    const { classes, response } = this.props;
    if (response && response.responseList.length >= 1) {
      var detail = response.responseList.filter(item => {
        return item.title === e.target.id;
      })[0];
      if (detail) {
        let detailPane = this.getDetailPane(classes, detail);
        this.setState({
          detailPane: detailPane
        });
        var arr = [];
        arr.push(detail);
        var tempObj = {};
        tempObj.responseList = arr;
        this.createMarkers(tempObj, null);
      }
    }
  };

  createMarkers = (places, myPosition) => {
    const { icon_url, icon_size } = this.props;
    var bounds = new google.maps.LatLngBounds();
    var existingMarkers = this.state.markers;
    if (existingMarkers && existingMarkers.length > 0) {
      existingMarkers.map(item => {
        item.setMap(null);
      });
    }
    var cardArr = [],
      markers = [];
    for (
      var i = 0, place;
      places && places.responseList && i < places.responseList.length;
      i++
    ) {
      place = places.responseList[i];
      cardArr.push(
        <h4
          id={place.title}
          onClick={this.handleItemClickOnMapCard}
          style={{ cursor: `pointer`, width: `100%` }}
          key={`mapitem-${i}`}
        >
          {place.title}
        </h4>
      );
      if (place.latitude && place.longitude) {
        var location = {
          lat: place.latitude,
          lng: place.longitude
        };

        var image = {
          url: icon_url,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(icon_size, icon_size)
        };
        var marker = new google.maps.Marker({
          map: this.map,
          icon: image,
          title: place.title,
          place: {
            placeId: place.place_id,
            location: location
          },
          content: place.title
        });
        markers.push(marker);
        let _this = this;
        google.maps.event.addListener(marker, `click`, function() {
          _this.handleItemClick({ target: { id: this.content } });
        });
        bounds.extend(location);
      }
    }
    if (places && places.responseList && places.responseList.length > 0) {
      this.map.fitBounds(bounds);
      this.setState({ mapCard: cardArr, markers: markers, notFound: false });
    } else if (
      (places && places.map && places.map.response) ||
      (places && places.responseList && places.responseList.length === 0)
    ) {
      this.setState({ notFound: true, mapCard: null });
    }
    if (myPosition && myPosition.lat !== 0 && myPosition.lng !== 0) {
      this.map.setCenter(
        new google.maps.LatLng(myPosition.lat, myPosition.lng)
      );
    }

    this.props.updateInitiateFlag(`initiateCreateMarkers`, false);
  };

  handleKeyPress = event => {
    if (event.key === `Enter`) {
      event.target.blur();
    }
  };

  renderInputComponent = inputProps => {
    const { classes } = this.props;
    return (
      <CustomInput
        inputProps={inputProps}
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControl
        }}
        onKeyPress={this.handleKeyPress}
      />
    );
  };

  componentDidUpdate() {
    const { initiateCreateMarkers, response, location } = this.props;
    if (
      this.state.mapLoaded &&
      this.map.fitBounds &&
      typeof root &&
      root.google &&
      initiateCreateMarkers
    ) {
      this.createMarkers(response, location);
    }
  }

  displayMap = () => {
    const { id, response, location } = this.props;
    if (sessionStorage.getItem(SHOW_MAP) === TRUE) {
      if (typeof root && !root.google) {
        setTimeout(() => {
          if (typeof root && root.google) {
            this.map = new root.google.maps.Map(this[id], {
              center: location,
              zoom: 22,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              maxZoom: 18
            });
            this.setState({ mapLoaded: true });
            this.createMarkers(response, location);
          }
        }, 5000);
      } else if (typeof root && root.google) {
        this.map = new root.google.maps.Map(this[id], {
          center: location,
          zoom: 22,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          maxZoom: 18
        });
        this.setState({ mapLoaded: true });
        this.createMarkers(response, location);
      }
    }
  };

  componentDidMount() {
    var map_script = browserDocument.createElement(`script`);
    map_script.id = `map_script`;
    map_script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GOOGLE_KEY}&libraries=places`;
    map_script.async = true;
    map_script.defer = true;
    if (
      browserDocument.getElementById(map_script.id) == null ||
      browserDocument.getElementById(map_script.id) == undefined ||
      !browserDocument.getElementById(map_script.id)
    )
      browserDocument.head.appendChild(map_script);
    this.displayMap();
  }
  selectedHospital=(suggestion)=>{
    // this.handleItemClick({ target: { id: suggestion.suggestion.title } })
    this.setState({
      value:suggestion && suggestion.suggestion.location,
    })
  }

  fetchPlace = async (e, suggestion) => {
    elementAutoScroll(`mapViewButton`);
    e.preventDefault();
    sessionStorage.setItem(SHOW_MAP, TRUE);
    if(suggestion){
      this.selectedHospital(suggestion)
    }
    this.displayMap();
    const { location } = this.props;
    var place = await this.getSuggestions(suggestion ? suggestion.suggestion.location : this.state.value);
    if (!place) {
      this.props.renderPlaces(location);
    }
    this.createMarkers(place);
    e.target && e.target.blur();
  };

  setNotFoundStatus = () => {
    this.setState({ notFound: false });
  };

  render() {
    const { suggestions, value } = this.state;

    const { classes, id, searchMessage, mapFormStyle, helperText } = this.props;

    const inputProps = {
      placeholder: searchMessage,
      autoComplete: `off`,
      value,
      onChange: this.onChange
    };

    return (
      <>
        <div
          ref={map => (this[id] = map)}
          style={
            sessionStorage.getItem(SHOW_MAP) === TRUE
              ? { height: `100vh` }
              : { display: `none` }
          }
        />
        {
          <>
            <div
              className={classes.mapForm}
              style={mapFormStyle ? mapFormStyle : {}}
            >
              <div id="snackbar">
                {this.state.notFound === true && (
                  <CustomSnackbar
                    open={true}
                    status={`error`}
                    message={`No nearby ${helperText} found.`}
                    flag={this.setNotFoundStatus}
                    style={{ position: `absolute`, top: `150px`,marginTop:`70px`}}
                  />
                )}
              </div>
              <GridContainer>
                <Hidden mdDown>
                  {!this.props.disableListView && (
                    <GridItem
                      lg={1}
                      style={{ paddingLeft: `0`, paddingRight: `0` }}
                    >
                      <Button
                        id="mapViewButton"
                        color="white"
                        size="sm"
                        onClick={() => this.props.changeView(`list`)}
                        style={{
                          marginTop: `30px`,
                          height: `74px`,
                          boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)`,
                          width: `99%`
                        }}
                      >
                        <div>
                          <div>
                            <List />
                          </div>
                          <div>List View</div>
                        </div>
                      </Button>
                    </GridItem>
                  )}
                </Hidden>
                <GridItem xs={12} lg={!this.props.disableListView ? 11 : 12}>
                  <Card className={classes.card} style={{ zIndex: `9999` }}>
                    <CardBody
                      formHorizontal
                      style={{ paddingTop: `10px`, paddingBottom: `0px` }}
                    >
                      <form onSubmit={this.fetchPlace}>
                        <GridContainer>
                          <GridItem xs={12} sm={8} md={8} lg={8}>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                              }
                              getSuggestionValue={this.getSuggestionValue}
                              onSuggestionSelected={this.fetchPlace}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                              renderInputComponent={this.renderInputComponent}
                              theme={theme}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4} lg={4}>
                            <Button
                              block
                              color="primary"
                              className={classes.button}
                              type="submit"
                            >
                              Search
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
            {this.state.mapCard && (
              <Hidden smDown implementation="css" className={classes.mapCard}>
                <GridContainer>
                  <GridItem md={12}>
                    <Card className={classes.card2} style={{ margin: `0` }}>
                      <CardBody>
                        {!this.state.detailPane && (
                          <CustomHidden
                            items={this.state.mapCard}
                            initState={1}
                            ctaLabel={`Show More`}
                            toggleCtaLabel={`Show Less`}
                            simple={true}
                            onToggle={function() {
                              elementAutoScroll(`mapViewButton`);
                            }}
                          />
                        )}
                        {this.state.detailPane && (
                          <div>{this.state.detailPane}</div>
                        )}
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </Hidden>
            )}
            {this.state.detailPane && (
              <Hidden
                mdUp
                implementation="css"
                className={classes.responsiveMapCard}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <Card className={classes.card2} style={{ margin: `0` }}>
                      <CardBody>
                        <div>{this.state.detailPane}</div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </Hidden>
            )}
            {sessionStorage.getItem(SHOW_MAP) !== TRUE && (
              <img src={defaultMap} width={`100%`} />
            )}
          </>
        }
      </>
    );
  }
}

CustomMap.propTypes = {
  classes: PropTypes.object.isRequired,
  searchMessage: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.string,
  icon_url: PropTypes.string,
  icon_size: PropTypes.number,
  url: PropTypes.string,
  cityUrl: PropTypes.string,
  helperText: PropTypes.string,
  defaultView: PropTypes.string,
  disableListView: PropTypes.bool,
  renderPlaces: PropTypes.func,
  location: PropTypes.object,
  updateInitiateFlag: PropTypes.func,
  response: PropTypes.object,
  changeView: PropTypes.func,
  initiateCreateMarkers: PropTypes.bool,
  mapFormStyle: PropTypes.object,
  updateResponse: PropTypes.func
};
export default withStyles(claimListStyle)(CustomMap);
