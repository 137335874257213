import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import PHCSlallabs from "./PHCSlallabs";
import PHCStheyrocare from "./PHCStheyrocare";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";


const PHCService = ({ classes, data}) => {
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var navPillArr = []
  data.allStrapiSections.edges.map(document => {
    var obj = {};
    let content;
    switch (document.node.strapiId) {
      
      case 791:
        content = <PHCSlallabs title={document.node.title} />;
        break;
    case 792:
            content = <PHCStheyrocare title={document.node.title} />;
            break;
      
    }
    if(content){
    if (document.node.strapiId == 283) {
      obj.tabButton = `Diagnostic Centres`;
    } else {
      if(document.node.strapiId==700 || document.node.strapiId==701 ){
        obj.tabButton=document.node.title.replace(/Network Providers for/g, '').replace(/&/g,"/").replace(/Treatment/g,'CARE')+' PROVIDER'
      }else{
      obj.tabButton = document.node.title;
      }
    }
    obj.tabContent = content;
    navPillArr.push(obj);
  }
  });

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
                <NavPills
                  color="primary"
                  tabs={navPillArr}
                  overflowhidden
                />
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
PHCService.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(customStaticPageStyle)(PHCService);

export const PHCServiceQuery = graphql`
  query PHCService {
    allStrapiSections(
      filter: { category: { eq: "network_hospital" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          title
          summary
          strapiId
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "locate_us" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
