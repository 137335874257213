import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import { HAS_SEARCHED, TRUE } from "./Util.jsx";
import { listData } from "ps-components/Maps/Util.jsx";

const theme = {
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: `none`
  },
  suggestion: {
    cursor: `pointer`,
    padding: `10px 20px`
  },
  suggestionHighlighted: {
    backgroundColor: `#ddd`
  }
};

class ListForm extends React.Component {
  state = {
    searchValue: ``,
    state: ``,
    stateList: [],
    city: ``,
    cityList: []
  };

  totalCount = 30;

  onSuggestionsStateFetchRequested = async ({ value }) => {
    var suggestions = await this.getStateSuggestions(value);
    this.setState({
      stateList: suggestions ? suggestions.slice(0, 10) : []
    });
  };

  onSuggestionsCityFetchRequested = async ({ value }) => {
    var suggestions = await this.getCitySuggestions(value);
    this.setState({
      cityList: suggestions ? suggestions.slice(0, 10) : []
    });
  };

  onSuggestionsStateClearRequested = () => {
    this.setState({
      stateList: []
    });
  };

  onSuggestionsCityClearRequested = () => {
    this.setState({
      cityList: []
    });
  };

  getCitySuggestions = async value => {
    var encodedValue = encodeURIComponent(this.state.state);
    if (this.state.state) {
      var url =
        this.props.cityUrl +
        `?state=` +
        encodedValue +
        `&category=` +
        this.props.category;
      var res = await fetch(url, {
        method: `GET`
      });
      var responseSuggestions = await res.json();
      var citySuggestion = responseSuggestions.responseList
        .filter(item => item.city.toLowerCase().includes(value.toLowerCase()))
        .map(item => {
          item.city =
            item.city.charAt(0).toUpperCase() + item.city.substring(1);
          return item;
        });
      return citySuggestion;
    }
  };

  getStateSuggestions = async value => {
    return this.statesList
      .filter(item => {
        return item.text.toLowerCase().includes(value.toLowerCase());
      })
      .map(item => {
        var textArr = item.text.split(` `);
        item.text = textArr
          .map(i => {
            return i.charAt(0).toUpperCase() + i.substring(1);
          })
          .join(` `);

        return item;
      });
  };

  getStateSuggestionValue = suggestion => {
    return suggestion.text !== undefined ? suggestion.text : ``;
  };

  getCitySuggestionValue = suggestion => {
    return suggestion.city !== undefined ? suggestion.city : ``;
  };

  renderCitySuggestion = suggestion => {
    var location = suggestion.city.replace(
      new RegExp(this.state.city, `ig`),
      function(matched) {
        return `<strong>` + matched + `</strong>`;
      }
    );

    return <Markdown source={location} escapeHtml={false} />;
  };

  renderStateSuggestion = suggestion => {
    var location = suggestion.text.replace(
      new RegExp(this.state.state, `ig`),
      function(matched) {
        return `<strong>` + matched + `</strong>`;
      }
    );
    return <Markdown source={location} escapeHtml={false} />;
  };

  onStateInputChange = (event, { newValue }) => {
    this.setState({
      state: newValue
    });
  };

  onCityInputChange = (event, { newValue }) => {
    this.setState({
      city: newValue
    });
  };

  handleInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleKeyPress = event => {
    if (event.key === `Enter`) {
      event.target.blur();
    }
  };

  renderInputComponent = inputProps => {
    const { classes } = this.props;
    return (
      <CustomInput
        inputProps={inputProps}
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControl
        }}
        onKeyPress={this.handleKeyPress}
      />
    );
  };

  componentDidUpdate() {
    const { initiateCreateList, response, upDateListFormState } = this.props;
    if (initiateCreateList) {
      this.props.createList(response, true);
    }
    upDateListFormState(this.state);
  }

  componentDidMount() {
    const { category, response, upDateListFormState } = this.props;

    if (category === `network`) {
      this.statesList = require(`ps-assets/states/network.json`);
    } else if (category === `agreed`) {
      this.statesList = require(`ps-assets/states/agreed.json`);
    } else if (category === `diagnostics`) {
      this.statesList = require(`ps-assets/states/diagnostics.json`);
    } else if (category === `labs`) {
      this.statesList = require(`ps-assets/states/labs.json`);
    } else if (category === `preferred`) {
      this.statesList = require(`ps-assets/states/preferred.json`);
    } else if (category === `non preferred`) {
      this.statesList = require(`ps-assets/states/non-preferred.json`);
    } else if (category === `PHCWomen`) {
      this.statesList = require(`ps-assets/states/PHCWomen.json`);
    } else if (category === `branch`) {
      this.statesList = require(`ps-assets/states/branch.json`);
    }else if (category == 'rehabilitation') {
      this.statesList = require(`ps-assets/states/rehabilitation.json`);
    }else if (category == 'pain') {
      this.statesList = require(`ps-assets/states/pain.json`);
    }
    upDateListFormState(this.state);
    this.props.createList(response, true);
  }

  render() {
    const { state, city } = this.state;

    const { classes, helperText } = this.props;

    const { searchFieldArr } = listData[helperText];

    const inputProps1 = {
      placeholder: `State`,
      id: `state`,
      value: state,
      autocomplete: `off`,
      onChange: this.onStateInputChange
    };

    const inputProps2 = {
      placeholder: `City`,
      id: `city`,
      value: city,
      autocomplete: `off`,
      onChange: this.onCityInputChange
    };

    return (
      <GridItem md={12} lg={!this.props.disableListView ? 11 : 12}>
        <Card className={classes.card} style={{ zIndex: `9999` }}>
          <CardBody
            formHorizontal
            style={{ paddingTop: `10px`, paddingBottom: `0px` }}
          >
            <form
              onSubmit={event => {
                event.preventDefault();
                sessionStorage.setItem(HAS_SEARCHED, TRUE);
                this.props.fetchList();
              }}
            >
              <GridContainer>
                {searchFieldArr.indexOf(`State`) != -1 && (
                  <GridItem
                    xs={12}
                    sm={9 / searchFieldArr.length}
                    md={9 / searchFieldArr.length}
                  >
                    <Autosuggest
                      suggestions={this.state.stateList}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsStateFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsStateClearRequested
                      }
                      getSuggestionValue={this.getStateSuggestionValue}
                      renderSuggestion={this.renderStateSuggestion}
                      inputProps={inputProps1}
                      renderInputComponent={this.renderInputComponent}
                      theme={theme}
                    />
                  </GridItem>
                )}
                {searchFieldArr.indexOf(`City`) != -1 && (
                  <GridItem
                    xs={12}
                    sm={9 / searchFieldArr.length}
                    md={9 / searchFieldArr.length}
                  >
                    <Autosuggest
                      suggestions={this.state.cityList}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsCityFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsCityClearRequested
                      }
                      getSuggestionValue={this.getCitySuggestionValue}
                      renderSuggestion={this.renderCitySuggestion}
                      inputProps={inputProps2}
                      renderInputComponent={this.renderInputComponent}
                      theme={theme}
                    />
                  </GridItem>
                )}
                {searchFieldArr.indexOf(`Common`) != -1 && (
                  <GridItem
                    xs={12}
                    sm={9 / searchFieldArr.length}
                    md={9 / searchFieldArr.length}
                  >
                    <CustomInput
                      inputProps={{
                        id: `searchValue`,
                        placeholder: listData[helperText].placeholder,
                        autocomplete: `off`
                      }}
                      value={this.state.searchValue}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControl
                      }}
                      onChange={this.handleInputChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={3} md={3}>
                  <Button
                    block
                    color="primary"
                    className={classes.button}
                    type="submit"
                  >
                    {listData[helperText].buttonText}
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}
ListForm.propTypes = {
  classes: PropTypes.object.isRequired,
  searchMessage: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.string,
  icon_url: PropTypes.string,
  icon_size: PropTypes.number,
  url: PropTypes.string,
  cityUrl: PropTypes.string,
  helperText: PropTypes.string,
  defaultView: PropTypes.string,
  disableListView: PropTypes.string,
  renderPlaces: PropTypes.func,
  location: PropTypes.object,
  showOnMap: PropTypes.func,
  updateInitiateFlag: PropTypes.func,
  response: PropTypes.object,
  initiateCreateList: PropTypes.bool,
  showTypeInListView: PropTypes.bool,
  statesList: PropTypes.array,
  fetchList: PropTypes.func,
  updateList: PropTypes.func,
  upDateListFormState: PropTypes.func,
  createList: PropTypes.func
};
export default withStyles(claimListStyle)(ListForm);