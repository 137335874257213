import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hidden from "@material-ui/core/Hidden";
import Button from "components/CustomButtons/Button.jsx";
import LocationOn from "@material-ui/icons/LocationOn";
import Map from "@material-ui/icons/Map";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { withPrefix } from "gatsby";
import ListForm from "./ListForm.jsx";
import {
  getIconForInfo,
  getIconData,
  removeCommas,
  iconData,
  getHeaders,
  listData,
  title_array,
  TRUE,
  SHOW_MAP,
  NO_RECORDS_FOUND,
  PLEASE_SEARCH,
  LOADING,
  removeSpecialCharacters
} from "ps-components/Maps/Util.jsx";

class CustomMap extends React.Component {
  state = {
    searchValue: ``,
    state: ``,
    stateList: [],
    city: ``,
    cityList: [],
    loaderMessage: this.props.tableView ? LOADING : PLEASE_SEARCH
  };

  totalCount = 30;

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: `none`,
            width: `92%`,
            marginLeft: `3%`,
            fontSize: `15px`
          },
          responsiveScroll: {
            maxHeight: `450px !important`,
            minHeight: `450px !important`,
            overflowX: `auto`,
            "&::-webkit-scrollbar": {
              width: `7px !important`
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: `10px`
            },
            "&::-webkit-scrollbar-thumb": {
              background: `#d5d6d7`,
              borderRadius: `10px`
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: `#b2b2b2`
            }
          }
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            color: `#000`,
            fontSize: `15px`
          },
          root: {
            paddingLeft: `15px !important`,
            paddingRight: `15px !important`,
            fontSize: `15px`,
            whiteSpace: `nowrap`
          }
        },
        MUIDataTableBodyRow: {
          root: {
            fontSize: `15px !important`,
            "&:nth-child(odd)": {
              backgroundColor: `rgba(0, 0, 0, 0.07)`
            },
            "&:hover": {
              backgroundColor: `transparent !important`,
              "&:nth-child(odd)": {
                backgroundColor: `rgba(0, 0, 0, 0.07) !important`
              }
            }
          }
        },
        MUIDataTableBody: {
          emptyTitle: {
            textTransform: `none !important`
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: `15px !important`,
            paddingLeft: `15px !important`,
            paddingRight: `15px !important`
          }
        },
        MUIDataTableSelectCell: {
          root: {
            display: `none`
          }
        }
      }
    });

  updateList = responseArr => {
    this.setState({
      list: responseArr
    });
  };

  getListSuggestions = async (pageNo, totalRecord) => {
    var url='';
    var res={};
    var responseSuggestions={};
    if(this.props.tableView){
      url =
        this.props.url +
        `?category=` +
        this.props.category +
        `&pageNo=` +
        pageNo +
        `&totalRecord=` +
        totalRecord;
      res = await fetch(url, {
        method: `GET`
      });
      responseSuggestions = await res.json();
      this.totalCount = responseSuggestions.totalCount;
      return responseSuggestions;
  }else{
    let search = ``,
      state = ``,
      city = ``;
    if (this.listFormState.searchValue)
      search = `&search=` + encodeURIComponent(this.listFormState.searchValue);
    if (this.listFormState.state) state = `&state=` + this.listFormState.state;
    if (this.listFormState.city) city = `&city=` + this.listFormState.city;
    if (search || state || city) {
      url =
        this.props.url +
        `?pageNo=` +
        pageNo +
        `&totalRecord=` +
        totalRecord +
        `&category=` +
        this.props.category +
        search +
        state +
        city;
      res = await fetch(url, {
        method: `GET`
      });
      responseSuggestions = await res.json();
      this.totalCount = responseSuggestions.totalCount;

      return responseSuggestions;
    } else {
      this.props.renderPlaces(this.props.location);
    }
  }
  };

  upDateListFormState = state => {
    this.listFormState = state;
  };

  removeCommas = string => {
    return string
      .split(`,`)
      .filter(substr => substr != ``)
      .join(`,`);
  };

  createList = (res, setTotalCount) => {
    const { showTypeInListView, helperText, classes } = this.props;
    var responseArr = [];
    var responseList = res && res.responseList;
    for (let index = 0; responseList && index < responseList.length; index++) {
      const element = responseList[index];
      Object.keys(element).forEach(key => {
        element[key] = removeSpecialCharacters(element[key]);
      });
      var temp = [];
      if (
        title_array.reduce(
          (acc, val) =>
            acc || listData[helperText].columnArr.indexOf(val) != -1,
          false
        ) ||
        helperText == `branch office`
      )
        temp.push(element.title);
      if (listData[helperText].columnArr.indexOf(`Address`) != -1)
        helperText != `branch office` &&
          temp.push(removeCommas(element.address + ` ` + element.area));
      if (listData[helperText].columnArr.indexOf(`City`) != -1)
        temp.push(element.city);
      if (listData[helperText].columnArr.indexOf(`State`) != -1)
        temp.push(element.state);
      if (listData[helperText].columnArr.indexOf(`Pincode`) != -1)
        temp.push(element.pin_code);
      if (listData[helperText].columnArr.indexOf(`STD`) != -1)
        temp.push(element.std_code);
      if (listData[helperText].columnArr.indexOf(`Contact Number`) != -1)
        temp.push(element.contact_number);
      showTypeInListView !== undefined &&
        temp.push(
          <div className={classes.typeIcon}>
            {getIconData(element.office_type || element.empanelment_status).map(
              (data, key) => {
                return (
                  <ReactSVG
                    key={key}
                    src={withPrefix(data.icon)}
                    style={{ width: `35px`, height: `35px` }}
                  />
                );
              }
            )}
          </div>
        );
        this.props.id !== 'thyrocare' && temp.push (
        <Button
          justIcon
          simple
          color="primary"
          style={{ width: `35px`, height: `35px` }}
          onClick={() => {
            sessionStorage.setItem(SHOW_MAP, TRUE);
            this.props.showOnMap(element, res);
          }}
        >
          <LocationOn />
        </Button>
      );
      responseArr.push(temp);
    }
    this.setState({
      list: responseArr
    });
    if (setTotalCount) this.totalCount = 30;
    this.props.updateInitiateFlag(`initiateCreateList`, false);
  };

  componentDidUpdate() {
    const { initiateCreateList, response } = this.props;
    if (initiateCreateList) {
      this.createList(response, true);
    }
  }

  componentDidMount() {
    const { prevResponse } = this.props;
    if (prevResponse && prevResponse.length != 0) {
      this.createList(prevResponse, false);
    }
  }

  fetchList = async (e, pageNo, totalRecord) => {
    if (e) e.preventDefault();
    if (!pageNo) pageNo = 1;
    if (!totalRecord) totalRecord = 30;
    this.setState({
      currentPage: pageNo,
      tableRecords: totalRecord,
      loaderMessage: LOADING
    });
    var places = await this.getListSuggestions(pageNo, totalRecord);
    if (places.responseList.length == 0)
      this.setState({ loaderMessage: NO_RECORDS_FOUND });
    this.createList(places, false);
  };

  render() {
    const { classes, showTypeInListView, helperText,tableView } = this.props;
    const { loaderMessage } = this.state;
    var options = {
      textLabels: {
        body: {
          noMatch: loaderMessage
        }
      },
      selectableRows: false,
      sort: false,
      filter: false,
      search: false,
      download: false,
      print: false,
      viewColumns: false,
      serverSide: true,
      responsive: `scroll`,
      rowsPerPage: 30,
      rowsPerPageOptions: [30, 50, 100],
      count: this.totalCount,
      onTableChange: (action, tableState) => {
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case `changePage`:
            this.fetchList(null, tableState.page + 1, this.state.tableRecords);
            break;
          case `changeRowsPerPage`:
            this.fetchList(
              null,
              this.state.currentPage,
              tableState.rowsPerPage
            );
            break;
        }
      }
    };

    return (
      <div>
        <div className={classes.mapForm}>
          {!tableView && <GridContainer>
            <Hidden mdDown>
              {!this.props.disableListView && this.props.id !== 'thyrocare' && (
                <GridItem
                  lg={1}
                  style={{ paddingLeft: `0`, paddingRight: `0` }}
                >
                  <Button
                    color="white"
                    size="sm"
                    onClick={() => this.props.changeView(`map`)}
                    style={{
                      marginTop: `30px`,
                      height: `74px`,
                      boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)`,
                      width: `99%`
                    }}
                  >
                    <div>
                      <div>
                        <Map />
                      </div>
                      <div>Map View</div>
                    </div>
                  </Button>
                </GridItem>
              )}
            </Hidden>
            <ListForm
              classes={classes}
              helperText={helperText}
              listData={listData}
              fetchList={this.fetchList}
              updateList={this.updateList}
              upDateListFormState={this.upDateListFormState}
              createList={this.createList}
              {...this.props}
            />
          </GridContainer>}
          
        </div>
        <div style={{ color: `black`, paddingTop: this.props.tableView ? `0px`:`50px` }}>
          <div>
            {showTypeInListView && (
              <div
                style={{
                  display: `flex`,
                  marginLeft: `3%`,
                  width: `auto`,
                  paddingRight: `5%`
                }}
              >
                {getIconForInfo(helperText).map((iconInfo, key) => {
                  return (
                    <div style={{ width: `100%` }} key={key}>
                      <div
                        style={{
                          marginLeft: `auto`,
                          marginRight: `auto`,
                          display: `flex`
                        }}
                      >
                        <ReactSVG
                          key={key}
                          src={withPrefix(iconData[iconInfo].icon)}
                          style={{ width: `35px`, height: `35px` }}
                        />
                        <h5>{iconData[iconInfo].text}</h5>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                key={this.totalCount}
                data={this.state.list}
                columns={getHeaders(helperText, showTypeInListView)}
                options={options}
              />
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    );
  }
}
CustomMap.propTypes = {
  classes: PropTypes.object.isRequired,
  searchMessage: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.string,
  icon_url: PropTypes.string,
  icon_size: PropTypes.number,
  url: PropTypes.string,
  cityUrl: PropTypes.string,
  helperText: PropTypes.string,
  defaultView: PropTypes.string,
  disableListView: PropTypes.string,
  renderPlaces: PropTypes.func,
  location: PropTypes.object,
  showOnMap: PropTypes.func,
  tableView:PropTypes.bool,
  updateInitiateFlag: PropTypes.func,
  response: PropTypes.object,
  prevResponse: PropTypes.object,
  changeView: PropTypes.func,
  initiateCreateList: PropTypes.bool,
  showTypeInListView: PropTypes.bool
};
export default withStyles(claimListStyle)(CustomMap);
