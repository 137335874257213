import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
import classNames from "classnames";
import PropTypes from "prop-types";
var apiBaseUrl = process.env.API_URL;

const PHCStheyrocare = ({ classes, title }) => {
  return (
    <GridContainer>
      <GridItem
        md={10}
        sm={10}
        className={classNames(classes.mrAuto, classes.mlAuto)}>
        <h2 className={classNames(classes.title, classes.textCenter)}>
          {title}
        </h2>
        <GridItem style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word',textAlign: 'center' }}>
        <h5>To book your Health check-up service, Please Call Thyrocare helpline no : 022-30900000/ Whats app support no : 9870666333</h5>
        <h5>Please Carry the Star- Health ID Card / Policy for identification.  In case of a family member of the Policy Holder, please carry a valid Govt id with Policy number.</h5>
        <h5>Note : A discount of  20%  on all Tests / Profile / Packages (Except Special Offers)  to Star Health Customers using promo code – <b>“STARTHYRO”</b></h5>
        </GridItem>
      </GridItem>
      <GridItem md={12} >
        <CustomMap
          id="thyrocare"
          searchMessage="Search thyrocare"
          category="reimb-thyrocare"
          icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
          icon_size={25}
          url={`${apiBaseUrl}hospital/search`}
          cityUrl={`${apiBaseUrl}hospital/city`}
          defaultView={`list`}
          helperText={`thyrocare`}
          disableListView={process.env.DISABLE_LISTVIEW === `true` ? true : false}
        />
      </GridItem>
    </GridContainer>
  );
};
PHCStheyrocare.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.object,
};
export default withStyles(mediaCenterStyle)(PHCStheyrocare);
