import React from "react";
import root from "window-or-global";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import PropTypes from "prop-types";
import MapView from "ps-components/Maps/MapView";
import ListView from "ps-components/Maps/ListView";
import { SHOW_MAP, FALSE, HAS_SEARCHED } from "./Util.jsx";
import { browserLocalStorage } from "util/localStorageHelper.js";

class CustomMap extends React.Component {
  state = {
    location: {
      lat: 0,
      lng: 0
    },
    view: `list`,
    response: [],
    initiateCreateMarkers: false,
    initiateCreateList: false,
  };
  geocodeFlag = 0;
  radius = 5;
  unit = `KM`;

  fetchPlaces = (postal_code, lat, lng, location) => {
    if (postal_code !== null) {
      browserLocalStorage.setItem(`postal_code`, postal_code);
    } else {
      postal_code = `600034`;
      browserLocalStorage.setItem(`postal_code`, `600034`);
    }
    fetch(this.props.url, {
      method: `POST`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify({
        category: this.props.category,
        postal_code: postal_code,
        lat: lat,
        lng: lng,
        radius: this.radius,
        unit: this.unit
      })
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          location: location,
          response: res,
          initiateCreateMarkers: true,
          initiateCreateList: true,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  geoCodePlaces = (results, lat, lng, location) => {
    let postal_code = null;
    for (var i = 0; i < results.length; i++) {
      for (let j = 0; j < results[i].address_components.length; j++) {
        const address_component = results[i].address_components[j];
        for (let k = 0; k < address_component.types.length; k++) {
          const element = address_component.types[k];
          if (element === `postal_code`) {
            postal_code =
              address_component.long_name || address_component.short_name;
            break;
          }
        }
        if (postal_code !== null) break;
      }
      if (postal_code !== null) break;
    }
    this.fetchPlaces(postal_code, lat, lng, location);
  };

  renderPlaces = (location, update_postal_code = false) => {
    if (location) {
      var lat = location.lat,
        lng = location.lng,
        latlng = new root.google.maps.LatLng(lat, lng);
      let postal_code = browserLocalStorage.getItem(`postal_code`);
      if (!postal_code || update_postal_code) {
        let geocoder = new root.google.maps.Geocoder();
        geocoder.geocode({ latLng: latlng }, (results, status) => {
          this.geocodeFlag = 1;
          if (
            status === root.google.maps.GeocoderStatus.OK &&
            results.length > 0
          ) {
            this.geoCodePlaces(results, lat, lng, location);
          } else {
            this.fetchPlaces(null, lat, lng, location);
          }
        });
        if (this.geocodeFlag === 0) {
          this.fetchPlaces(null, lat, lng, location);
        }
      } else {
        this.fetchPlaces(postal_code, lat, lng, location);
      }
    }
    this.setState({ prevResponse: [] });
  };

  updateInitiateFlag = (name, flag) => {
    this.setState({ [name]: flag });
  };

  showOnMap = (element, response) => {
    this.setState({
      response: { responseList: [element] },
      view: `map`,
      prevResponse: response
    });
  };

  handleResize = () => {
    if (root.innerWidth <= 1280) {
      this.setState({ view: `map` });
    }
  };

  componentWillUnmount() {
    root.removeEventListener(`resize`, this.handleResize);
  }

  componentDidMount=async()=> {
    sessionStorage.setItem(SHOW_MAP, FALSE);
    sessionStorage.setItem(HAS_SEARCHED, FALSE);
    if(this.props.tableView){
      let url =
        this.props.url +
        `?category=` +
        this.props.category +
        `&pageNo=`+this.props.pageNo+
        `&totalRecord=`+this.props.totalRecord;
      fetch(url, {
        method: `GET`,
      })
        .then(res => res.json())
        .then(res => {
          this.setState({
            response: res,
            initiateCreateMarkers: true,
            initiateCreateList: true,
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
    const { defaultView } = this.props;
    this.setState({ view: defaultView });
    root.addEventListener(`resize`, this.handleResize);
    if (root.innerWidth <= 1280) {
      this.setState({ view: `map` });
    }
  }

  updateResponse = response => {
    this.setState({ response: response });
  };

  changeView = async view => {
    var location = Object.assign({}, this.state.location);
    this.setState({ view: view });
    if (location.lat !== 0 || location.lng !== 0) {
      this.renderPlaces(location);
    }
  };

  render() {
    const {
      classes,
      id,
      searchMessage,
      helperText,
      icon_url,
      icon_size,
      url,
      category,
      mapFormStyle,
      cityUrl,
      tableView,
      disableListView,
      showTypeInListView
    } = this.props;

    const {
      response,
      location,
      initiateCreateList,
      initiateCreateMarkers,
      prevResponse
    } = this.state;
    return (
      <div>
        <div className={classes.mapHeight}
          style={{
            paddingTop: `50px`,
            overflowY: `hidden`,
            position: `relative`
          }}
        >
          {this.state.view === `map` && (
            <MapView
              initiateCreateMarkers={initiateCreateMarkers}
              updateInitiateFlag={this.updateInitiateFlag}
              id={id}
              icon_url={icon_url}
              icon_size={icon_size}
              searchMessage={searchMessage}
              location={location}
              response={response}
              classes={classes}
              changeView={this.changeView}
              url={url}
              category={category}
              renderPlaces={this.renderPlaces}
              updateResponse={this.updateResponse}
              mapFormStyle={mapFormStyle}
              disableListView={disableListView}
              helperText={helperText}
            />
          )}
          {this.state.view === `list` && (
            <ListView
              initiateCreateList={initiateCreateList}
              updateInitiateFlag={this.updateInitiateFlag}
              id={id}
              helperText={helperText}
              response={response}
              classes={classes}
              changeView={this.changeView}
              url={url}
              tableView={tableView}
              renderPlaces={this.renderPlaces}
              category={category}
              location={location}
              cityUrl={cityUrl}
              showOnMap={this.showOnMap}
              disableListView={disableListView}
              showTypeInListView={showTypeInListView}
              prevResponse={prevResponse}
            />
          )}
        </div>
      </div>
    );
  }
}
CustomMap.propTypes = {
  classes: PropTypes.object.isRequired,
  searchMessage: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.string,
  icon_url: PropTypes.string,
  icon_size: PropTypes.number,
  pageNo:PropTypes.number,
  totalRecord:PropTypes.number,
  url: PropTypes.string,
  cityUrl: PropTypes.string,
  helperText: PropTypes.string,
  defaultView: PropTypes.string,
  disableListView: PropTypes.bool,
  tableView:PropTypes.bool,
  mapFormStyle: PropTypes.object,
  showTypeInListView: PropTypes.bool
};
export default withStyles(claimListStyle)(CustomMap);