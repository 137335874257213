import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
import classNames from "classnames";
import PropTypes from "prop-types";
var apiBaseUrl = process.env.API_URL;

const NHNetworkHospitals = ({ classes, title }) => {
  return (
    <GridContainer>
      <GridItem
        md={10}
        sm={10}
        className={classNames(classes.mrAuto, classes.mlAuto)}>
        <h2 className={classNames(classes.title, classes.textCenter)}>
          {title}
        </h2>
      </GridItem>
      <GridItem style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word',textAlign: 'center' }} >
      <h5>To book your Health check-up service, Please Call Dr.Lal Path labs helpline no : 011-49885050.</h5>
        <h5>Please Carry the Star Health ID Card / Policy for identification. In case of a family member of the Policy Holder, please carry a valid Govt id with Policy number.</h5>
        <h5>Note : A discount of 20% on all Pathology & Radiology tests from all Dr.Lal Pathlab Outlets / Patient Service Centres to all Star Health Customers.</h5>
      </GridItem>
      <GridItem md={12}>
        <CustomMap
          id="reimb-dr.lal"
          searchMessage="Search Diagnostic Centers"
          category="reimb-dr.lal"
          icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
          icon_size={25}
          url={`${apiBaseUrl}hospital/search`}
          cityUrl={`${apiBaseUrl}hospital/city`}
          defaultView={`list`}
          helperText={`reimb-dr.lal`}
          disableListView={process.env.DISABLE_LISTVIEW === `true` ? true : false}
        />
      </GridItem>
    </GridContainer>
  );
};
NHNetworkHospitals.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.object,
};
export default withStyles(mediaCenterStyle)(NHNetworkHospitals);
