import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import PropTypes from "prop-types";
class CustomHidden extends React.Component {

  state = {
    onHover: false
  }

  onHoverEnter = () => {
    this.setState({ onHover: true });
  }

  onHoverLeave = () => {
    this.setState({ onHover: false });
  }

  toggleLoadMore = () => {
    if(this.props.onToggle){
      this.props.onToggle();
    }
    var len = this.state.item === this.props.initState ? this.props.items.length : this.props.initState;
    this.setState({ item: len });
  }

  componentDidMount() {
    const { initState } = this.props;
    this.setState({ item: initState });
  }

  render() {
    const { items, ctaLabel, toggleCtaLabel, initState, round, textAlign, colorCode, simple } = this.props;
    return (
      <div style={{ textAlign: textAlign }}>
        <GridContainer>
          {
            items && items.slice(0, this.state.item).map((res) => {
              return res;
            })
          }
        </GridContainer>
        {items && initState < items.length && <Button simple={simple} color="primary" round={round} onClick={this.toggleLoadMore} style={{ backgroundColor: colorCode }}>{this.state.item === initState ? ctaLabel : toggleCtaLabel}</Button>}
      </div>
    );
  }

}
CustomHidden.propTypes = {
  initState: PropTypes.number,
  items: PropTypes.array.isRequired,
  ctaLabel: PropTypes.string,
  toggleCtaLabel: PropTypes.string,
  round: PropTypes.bool,
  textAlign: PropTypes.string,
  colorCode: PropTypes.string,
  simple: PropTypes.bool,
  onToggle: PropTypes.func
};

export default CustomHidden;
