import { browserDocument } from "util/localStorageHelper";

export const HAS_SEARCHED = `has_searched`;

export const SHOW_MAP = `show_map`;

export const TRUE = `true`;

export const FALSE = `false`;

export const PLEASE_SEARCH = `Please Search`;

export const LOADING = `Loading...`;

export const NO_RECORDS_FOUND = `No records found`;

export var old_list = [];

export const listData = {
  "network hospitals": {
    columnArr: [
      `Hospital Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: `Type`,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    icon: `/megaMenu-network.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "agreed network hospitals": {
    columnArr: [
      `Hospital Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    icon: `/megaMenu-anh.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "Ayush Hospitals": {
    columnArr: [
      `Hospital Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "pain management treatment": {
    columnArr: [
      `Hospital Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    icon: `/Pain_management_care_provider.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "rehabilitation & hospice care": {
    columnArr: [
      `Hospital Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    icon: `/RehabilitationHospice_care_provider.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "diagnostic centers": {
    columnArr: [
      `Center Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Center Name...`,
    buttonText: `Locate Centers`,
    icon: `/megaMenu-diagnostic.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "thyrocare": {
    columnArr: [
      `City`,
      `State`,
      `Pincode`
    ],
    type: ``,
    placeholder: `Search Pincode, Center Name...`,
    buttonText: `Locate Centers`,
    icon: `/megaMenu-diagnostic.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "reimb-dr.lal": {
    columnArr: [
      `Address`,
      `City`,
      `State`,
      `Pincode`
    ],
    type: ``,
    placeholder: `Search Pincode, Center Name...`,
    buttonText: `Locate Centers`,
    icon: `/megaMenu-diagnostic.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "network labs": {
    columnArr: [
      `Lab Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Lab Name...`,
    buttonText: `Locate Labs`,
    icon: `/megaMenu-labs.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "preferred hospitals": {
    columnArr: [
      `Hospital Name`,
      `Address`,
      `City`,
      `State`,
      `Pincode`,
      `STD`,
      `Contact Number`
    ],
    type: ``,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    icon: `/megaMenu-preferred.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  },
  "non-preferred hospitals": {
    columnArr: [`Hospital Name`, `Address`],
    type: ``,
    placeholder: `Search Pincode, Hospital Name...`,
    buttonText: `Locate Hospitals`,
    icon: `/megaMenu-network.svg`,
    typeText: ``,
    searchFieldArr: [`Common`]
  },
  "PHCWomen hospitals": {
    columnArr: [
      `Hospital / Centre Name`,
      `Address`,
       `City`,
       `State`,
       `Contact Number`
      ],
    type: ``,
    placeholder: `Search City, Hospital / Centre Name...`,
    buttonText: `Search`,
    icon: `/megaMenu-network.svg`,
    typeText: ``,
    searchFieldArr: [`Common`]
  },
  "branch office": {
    columnArr: [`Address`, `City`, `State`, `Pincode` , `Contact Number`],
    type: `Branch Type`,
    placeholder: `Search Pincode, Branch Name...`,
    buttonText: `Locate Branch`,
    icon: `/megaMenu-network.svg`,
    typeText: ``,
    searchFieldArr: [`State`, `City`, `Common`]
  }
};

export const iconData = {
  NWH: {
    text: `Network Hospital`,
    icon: `/megaMenu-network.svg`,
    type: `network hospitals`,
    description: ``
  },
  ANH: {
    text: `Agreed Network Hospitals`,
    icon: `/megaMenu-anh.svg`,
    type: `network hospitals`,
    description: ``
  },
  PNH: {
    text: `Valuable Service Providers(Hospitals)`,
    icon: `/megaMenu-preferred.svg`,
    type: `network hospitals`,
    description: ``
  },
  BO: {
    text: `Branch Office`,
    icon: `/web__Branch Office.svg`,
    type: `branch office`,
    description: ``
  },
  ZO: {
    text: `Zonal Office`,
    icon: `/web__Zonal office.svg`,
    type: `branch office`,
    description: ``
  },
  DAB: {
    text: `Direct Agent Branch`,
    icon: `/web__dab.svg`,
    type: `branch office`,
    description: ``
  },
  "CORP  I": {
    text: `Corporate Annexure`,
    icon: `/web-corp-1.svg`,
    type: `branch office`,
    description: ``
  },
  "CORPANNEXURE (IT)": {
    text: `Corporate Annexure (IT)`,
    icon: `/web-corpannexure.svg`,
    type: `branch office`,
    description: ``
  },
  "CORPANNEXURE (CLAIMS)": {
    text: `Corporate Annexure (CLAIMS)`,
    icon: `/web-corp-claims.svg`,
    type: `branch office`,
    description: ``
  },
  AO: {
    text: `Area Office`,
    icon: `/web-ao.svg`,
    type: `branch office`,
    description: ``
  },
  RBO: {
    text: `Rural Branch Office`,
    icon: `/web-rab.svg`,
    type: `branch office`,
    description: ``
  },

};

const corp_array = [
  `CORP  I`,
  `CORPANNEXURE (IT)`,
  `CORPANNEXURE (IT)`,
  `CORPANNEXURE (CLAIMS)`
];

export const getIconData = switcher => {
  let iconArray = [];
  switcher.split(`/`).map(type => {
    if (!corp_array.includes(type)) {
      type = getType(type)
        .icon.toUpperCase()
        .split(` `)[0];
    }
    switch (type) {
      case `ANH`:
      case `PNH`:
      case `NWH`:
      case `BO`:
      case `ZO`:
      case `AO`:
      case `RBO`:
      case `DAB`:
      case `CORP  I`:
      case `CORPANNEXURE (IT)`:
      case `CORPANNEXURE (CLAIMS)`:
        iconArray.push(iconData[type]);
        break;
      default:
        iconArray.push({
          text: switcher,
          icon: `/megaMenu-network.svg `
        });
    }
  });
  return iconArray;
};

export const removeCommas = string => {
  return string
    .split(`,`)
    .filter(substr => substr != `` && substr != ` `)
    .join(`,`);
};

export const title_array = [`Hospital Name`, `Center Name`, `Lab Name`];

export const getIconForInfo = type => {
  return Object.keys(iconData).filter(key => iconData[key].type == type);
};

export const getHeaders = (helperText, showTypeInListView) => {
    return showTypeInListView
      ? listData[helperText].columnArr.concat([
        listData[helperText].type,
        `Locate`
      ])
      : helperText == 'thyrocare'?listData[helperText].columnArr.concat([` `]) : listData[helperText].columnArr.concat([`Locate`]);
};

export const getType = type => {
  let splitedType = type.split(` `);
  if (corp_array.includes(type)) {
    splitedType[0] = type;
    splitedType[1] = ``;
  }
  let typeDescription = { description: ``, icon: splitedType[0] };
  switch (splitedType[0]) {
    case `NWH`:
      typeDescription.description = `Network Hospital`;
      break;
    case `ANH`:
      typeDescription.description = `Agreed Network Hospital`;
      break;
    case `PNH`:
      typeDescription.description = `Valuable Service Providers(Hospitals)`;
      break;
    case `BO`:
      typeDescription.description = `Branch Office`;
      break;
    case `ZO`:
      typeDescription.description = `Zonal Office`;
      break;
    case `DAB`:
      typeDescription.description = `Direct Agent Branch`;
      break;
    case `CORP  I`:
      typeDescription.description = `Corporate Annexure`;
      break;
    case `CORPANNEXURE (IT)`:
      typeDescription.description = `Corporate Annexure (IT)`;
      break;
    case `CORPANNEXURE (CLAIMS)`:
      typeDescription.description = `Corporate Annexure (CLAIMS)`;
      break;
    case `AO`:
      typeDescription.description = `Area Office`;
      typeDescription.icon = `AO`;
      break;
    case `RBO`:
      typeDescription.description = `Rural Branch Office`;
      break;
    case `BOI`:
      typeDescription.description = `Branch Office I`;
      typeDescription.icon = `BO`;
      break;
    case `BOII`:
      typeDescription.description = `Branch Office II`;
      typeDescription.icon = `BO`;
      break;
    case `BOIII`:
      typeDescription.description = `Branch Office III`;
      typeDescription.icon = `BO`;
      break;
    case `AOI`:
      typeDescription.description = `Area Office I`;
      typeDescription.icon = `AO`;
      break;
    case `AOII`:
      typeDescription.description = `Area Office II`;
      typeDescription.icon = `AO`;
      break;
    default:
      typeDescription.description = type;
  }
  typeDescription.description += splitedType[1] ? ` ${splitedType[1]}` : ``;
  return typeDescription;
};

export const removeSpecialCharacters = str =>
  typeof str === `string`
    ? str
      .split(``)
      .filter(ch => ch.charCodeAt() !== 65533)
      .join(``)
    : str;

export const elementAutoScroll = function(Id) {
  if (Id){
    let mapViewButton = browserDocument.getElementById(Id);
    if (mapViewButton){
      mapViewButton.scrollIntoView({behavior: `smooth`, inline: `nearest`});
    } 
  }
};